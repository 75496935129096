<template>
    <a @click="getCustomer()">{{params.data.Email_Address}}</a>
</template>

<script>
import Vue from "vue";

export default  Vue.extend({
    methods: {
        getCustomer(){
            this.params.context.componentParent.methodFromParentGetCustomer(
                this.params.data.Id
            );
        }
    },
})
</script>

<style>

</style>
